@import-normalize;
/* bring in normalize.css styles */

/* Main */

html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  display: block;
  width: 100%;
  overflow-x: hidden;
}

/* General */

.link {
  position: relative;
  color: var(--gold);
  font-size: 18px;
  font-weight: 600;
}

.link::before {
  content: " ";
  display: block;
  position: absolute;
  border-bottom: solid 1px var(--gold);
  bottom: -1px;
  width: 0%;
  left: 0%;
  margin: 0 auto;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.link:hover::before {
  border-bottom: solid 1px var(--gold);
  width: 100%;
  left: 0%;
}

:root {
  --black: #111515;
  --darkgray: #29303A;
  --lightgray: #CACACA;
  --white: #fff;
  --gold: #DAA520;
}

/* Fonts */

h1 {
  font-family: "Larsseit", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 800;
  word-spacing: 5px;
  color: var(--black);
  -webkit-font-smoothing: auto;
}

h2 {
  font-family: "Larsseit", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: var(--black);
  letter-spacing: -1.45px;
}

h3 {
  font-family: "Larsseit", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: var(--black);
  letter-spacing: -0.8px;
}

h4 {
  font-family: "Larsseit", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 26px;
  line-height: 46px;
  color: var(--black);
  letter-spacing: 0.4px;
}

h5 {
  font-family: "Larsseit", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 21px;
  line-height: 37px;
  color: var(--black);
}

h5 a {
  font-weight: 500;
  color: var(--gold);
  text-decoration: none;
}

@media (max-width: 767px) {

  /* Fonts */
  h1 {
    word-spacing: 0;
  }

  h2 {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -1px;
  }

  h3 {
    font-size: 21px;
    line-height: 27px;
    letter-spacing: -0.4px;
  }

  h4 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0px;
  }

  h5 {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1130px) {
  h2 {
    font-size: 42px;
    line-height: 54px;
  }

  h3 {
    font-size: 26px;
    line-height: 32px;
  }

  h4 {
    font-size: 24px;
    line-height: 44px;
    letter-spacing: 0.3px;
  }

  h5 {
    font-size: 20px;
    line-height: 34px;
  }
}

@media (min-width: 768px) and (max-width: 860px) {
  h2 {
    font-size: 38px;
    line-height: 48px;
  }

  h3 {
    font-size: 25px;
  }

  h4 {
    font-size: 22px;
    line-height: 38px;
    letter-spacing: 0.2px;
  }

  h5 {
    font-size: 18px;
    line-height: 32px;
  }
}