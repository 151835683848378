.end {
  padding: 0 20px;
  padding-top: 140px;
  padding-bottom: 225px;
  text-align: center;
}

.end-title {
  max-width: 940px;
  margin: 30px auto 30px;
  font-size: 120px;
  line-height: 114px;
  letter-spacing: -6.5px;
}

@media (min-width: 768px) and (max-width: 1130px) {
  .end {
    padding: 0 40px;
  }
}

@media (min-width: 767px) and (max-width: 1003px) {
  .end {
    padding-top: 130px;
    padding-bottom: 190px;
  }

  .end-title {
    font-size: 10.8vw;
    line-height: 10.3vw;
    letter-spacing: -0.6vw;
  }
}

@media (min-width: 768px) and (max-width: 860px) {
  .end {
    padding-top: 105px !important;
    padding-bottom: 170px !important;
  }
}

@media (max-width: 767px) {
  .end {
    padding: 75px 30px 160px;
  }

  .end-title {
    font-size: 58px;
    line-height: 58px;
    letter-spacing: -3.4px;
    max-width: 500px;
  }
}
