.scroll-to-top {
    position: fixed;
    bottom: 2.25rem;
    right: 2rem;
    cursor: pointer;
}

@media (max-width: 767px) {
    .scroll-to-top {
        bottom: 5rem;
        right: .75rem;
    }
}