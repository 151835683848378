.intro {
  margin: 15px auto 145px;
  text-align: center;
  padding: 0 20px;
}

.intro-title {
  max-width: 1100px;
  margin: auto;
  font-size: 138px;
  line-height: 138px;
  letter-spacing: -9px;
}

.intro-text {
  max-width: 980px;
  margin: 11px auto 28px;
}

.intro-link {
  color: #d9b002;
  display: inline-block;
  margin: 0 0 0 26px;
  text-decoration: none;
}

.intro-link svg {
  vertical-align: middle;
}

.intro-link span {
  vertical-align: middle;
  display: inline-block;
  margin: 3px 0 0 4px;
}

@media (min-width: 768px) and (max-width: 1130px) {
  .intro {
    padding: 0 30px;
    margin: 10px auto 115px;
  }

  .intro-title {
    font-size: 11.5vw;
    line-height: 11.5vw;
    letter-spacing: -0.7vw;
  }

  .intro-text {
    max-width: 900px;
    margin: 7px auto 30px;
    line-height: 42px;
  }
}

@media (min-width: 768px) and (max-width: 860px) {
  .intro {
    margin: 5px auto 90px;
  }

  .intro-text {
    margin: 10px auto 15px;
    line-height: 39px;
  }
}

@media (min-width: 521px) and (max-width: 767px) {
  .intro {
    padding: 0 30px;
  }

  .intro-title {
    font-size: 11.1vw !important;
    line-height: 11.1vw !important;
    letter-spacing: -0.6vw !important;
  }
}

@media (max-width: 767px) {
  .intro {
    margin: 0px auto 40px;
  }

  .intro-title {
    font-size: 70px;
    line-height: 64px;
    letter-spacing: -4px;
  }

  .intro-text {
    margin: 15px auto 20px;
  }

  .intro-link {
    margin: 22px 0 0 0;
  }

  .button {
    padding: 22px 0 21px;
    width: 85%;
    max-width: 330px;
    display: block;
    margin: auto;
  }
}
