.project-header {
    margin: 115px auto 145px;
    text-align: center;
    padding: 0 20px;
}

.header-title {
    max-width: 1100px;
    margin: auto;
    font-size: 138px;
    line-height: 138px;
    letter-spacing: -5px;
}

.subheader {
    max-width: 820px;
    margin: 30px auto 26px;
}

@media (max-width: 1130px) and (min-width: 768px) {
    .project-header {
        font-size: 10.8vw;
        line-height: 10.3vw;
        letter-spacing: -0.6vw;
    }
    .subheader {
        max-width: 750px;
        margin: 20px auto 20px;
    }
}

@media (min-width: 767px) and (max-width: 1003px) {
    .project-header {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .header-title {
        font-size: 10.8vw;
        line-height: 10.3vw;
        letter-spacing: -0.6vw;
    }
    .subheader {
        max-width: 750px;
        margin: 20px auto 20px;
    }
}

@media (min-width: 768px) and (max-width: 860px) {
    .project-header {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }
    .subheader {
        max-width: 690px;
    }
}

@media (max-width: 767px) {
    .header-title {
        font-size: 58px;
        line-height: 58px;
        letter-spacing: -3.4px;
        max-width: 500px;
    }
    .subheader {
        margin: 15px auto 20px;
        max-width: 620px;
    }
}

@media only screen and (max-width:375px) {
    .project-header {
        margin: 0;
    }
}