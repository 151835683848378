.mgb-logo {
    padding: 16px 30px 15px;
    display: flex;
    justify-content: center;
}

.grace-logo {
    height: 50px;
}

@media (max-width: 425px) {
    .grace-logo {
        margin: 0 auto;
    }
}

@media (max-width: 375px) {
    .mgb-logo {
        justify-content: center;
    }

    .grace-logo {
         margin: 0 auto;
    }
}

