#skills {
    margin: 115px auto 145px;
    text-align: center;
    padding: 0 20px;
}

.skills-title {
    max-width: 1100px;
    margin: auto;
    font-size: 138px;
    line-height: 138px;
    letter-spacing: -5px;
}

.skills-wrapper {
    margin: 115px auto 145px;
}

@media (min-width: 768px) and (max-width: 1130px) {
    .skills-title {
        font-size: 11.5vw;
        line-height: 11.5vw;
        letter-spacing: -0.7vw;
    }
}

@media (min-width: 521px) and (max-width: 767px) {
    .skills-title {
        font-size: 11.1vw !important;
        line-height: 11.1vw !important;
        letter-spacing: -0.6vw !important;
    }
}

@media (max-width: 767px) {
    #skills {
        margin: 40px auto 40px;
    }

    .skills-wrapper {
        flex-wrap: wrap;
        margin: 40px auto;
    }

    .skills-title {
        font-size: 58px;
        line-height: 58px;
        letter-spacing: -3.4px;
        max-width: 500px;
    }

    .skills-icons {
        font-size: 1em;
    }

    .lead {
        font-size: 1rem;
    }
}