.box {
    overflow: hidden;
    position: relative;
}

.box img {
    width: 100%;
    height: auto;
}


.box .inner-content {
    width: 100%;
    top: 70%;
    left: 50%;
}

.box .title {
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 3px;
}

.box .post {
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin: 0 0 12px;
    display: block;
}

.box .icon {
    padding: 0;
    margin: 0;
    list-style: none;
}

.box:hover .icon {
    opacity: 1;
    transform: rotate(0deg);
}

.box .icon li {
    display: inline-block;
    margin: 0 4px;
}

.box .icon li a {
    color: var(--gold);
    font-size: 18px;
    line-height: 32px;
    height: 35px;
    width: 35px;
    border-radius: 10px 0 10px 0;
    display: block;
    transition: all 0.3s;
    margin-right: 1rem;
    font-weight: 600;
}

@media only screen and (max-width:990px) {
    .box {
        margin: 0 0 30px;
    }
}

@media only screen and (max-width:766px) {
    .box .title {
        font-size: 20px;
    }

    .box .post {
        font-size: 14px;
    }
}

@media only screen and (max-width:375px) {
    .box .title {
        font-size: 15px;
    }

    .box .post {
        font-size: 12px;
        letter-spacing: 0;
    }

}