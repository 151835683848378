.download-resume-btn {
  font-family: "Larsseit", Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  padding: 24px 48px 23px;
  background-color: #d9b002;
  display: inline-block;
  vertical-align: middle;
  border: none;
  border-radius: 50px;
  box-shadow: 0px 23px 28px -24px rgba(212, 143, 9, 0.65);
  transition: all 0.2s ease;
}

.download-resume-btn:hover {
  cursor: pointer;
  background-color: #f1bc58;
}

@media (max-width: 767px) {
  .download-resume-btn {
    padding: 22px 0 21px;
    width: 85%;
    max-width: 330px;
    display: block;
    margin: auto;
  }
}
