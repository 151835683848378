
.skills-icons i {
    width: 1.8em;
    font-size: 4em;
}

.skills-icons:hover {
    background: linear-gradient(to right, #e9e9af, #ebc785, #f0ab36, #335275, #193347, #103258, #1a324b);
    background-size: 500% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 2s linear infinite;
}

@keyframes gradient {
    0% {
        background-position: 0 0
    }

    100% {
        background-position: 100% 0
    }
}

.figma {
    padding: 0em !important;
    display: contents !important;
}



