.topbar {
  border-bottom: 1px solid #daa520;
  text-align: right;
  padding: 16px 30px 15px;
}

.nav {
  display: flex;
  justify-content: flex-end;
}

.nav li {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  list-style-type: none;
  color: var(--black);
}

.nav li:hover {
  opacity: 0.5;
}

.nav a {
  margin-right: 30px;
}

.top-left {
  text-align: left;
}

@media (min-width: 768px) and (max-width: 1130px) {
  .topbar {
    padding: 14px 30px 13px;
  }
}

@media (max-width: 767px) {
  .topbar {
    padding: 12px 20px 11px;
  }

  .nav {
    justify-content: space-evenly;
  }

  .nav li {
    transform: scale(0.95);
  }
}
