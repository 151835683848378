.about-wrapper {
    background-color: #E5E5E5;
    position: relative;
    padding: 120px 0 180px;
}

.about-section {
    position: relative;
    margin: 65px auto 0;
    max-width: 1180px;
    text-align: center;
    padding: 0 20px;
}

.grace-avatar {
    position: absolute;
    max-width: 500px;
    top: 25%;
    left: 58%;
}

.about-title {
    max-width: 500px;
    margin-top: 40px;
}

.about-text {
    max-width: 530px;
}

@media (max-width: 767px) {
    .about-wrapper {
        padding: 80px 0 40px;
    }

    .about-title {
        display: flex;
        margin-top: 10px;
    }

    .grace-avatar {
        width: 50%;
        left: 25%;
        top: -10%;
    }

    .container-s {
        padding-top: 40%;
    }
}